@use '../../styles/variables' as variables;
@use '../../styles/functions' as functions;


$brand-header-font-size: 22px;
$brand-description-font-size: 14px;


.brand {

    .brand__item {
        margin: 0 15px;

        .brand__header {
            font-size: $brand-header-font-size;
            margin-top: 35px;
            margin-bottom: 35px;
            font-weight: bold;
            letter-spacing: 2px;
        }

        .brand__description {
           font-size: $brand-description-font-size;
           padding-right: 80px;
           text-align: justify;
        }
    }
}

@media only screen and (max-width: variables.$lg-breakpoint) {
    .brand__item {
        flex-direction: column !important;

        .brand__info {
            order: 2;
        }

        .brand__carousel {
            order: 1;
        }
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {
    .brand {

        .brand__item {

            .brand__header {
               font-size: functions.sizeScaling($brand-header-font-size, variables.$md-scaling);
            }

            .brand__description {
                font-size: 16px;
                padding-right: 0;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .brand {

        .brand__item {

            .brand__header {
                font-size: 18px;
                letter-spacing: 1px;
            }

            .brand__description {
                font-size: 11px;
            }
        }
    }
}