@use '../../styles/variables' as variables;
@use '../../styles/functions' as functions;
@use '../../styles/mixins' as mixins;


.content {
    margin: variables.$section-margin;

    .brand__cards {
        animation: fade 0.2s ease-in;
    }
}