@use '../../styles/variables' as variables;


.logo-primary {
    width: 350px;
    height: auto;
}

.logo-secondary {
    width: 60px;
    height: auto;
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    
    .logo-primary {
        width: 220px;
        height: auto;
    }
}