

.collapse-list {
    width: auto;
    overflow: hidden;
}

.collapse-list--expanded {
    max-height: 500px;
    transition: max-height 0.4s ease-in;
}

.collapse-list--collapsed {
    max-height: 0;
    transition: max-height 0.4s ease-out;
}