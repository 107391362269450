@use '../../styles/variables' as variables;
@use '../../styles/functions' as functions;
@use '../../styles/mixins' as mixins;


$brand-card-image-width: 300px;
$brand-card-image-height: 450px;

$brand-card-image-width-md: 200px;
$brand-card-image-height-md: 300px;

$brand-card-image-width-sm: 160px;
$brand-card-image-height-sm: 240px;  

$brand-card-title-font-size: 22px;
$brand-card-title-font-size-md: 16px;
$brand-card-title-font-size-sm: 12px;
$brand-card-title-padding: 5px;


.brand__card {
    position: relative;
    width: $brand-card-image-width;
    height: $brand-card-image-height;
    margin: 15px;
    overflow: hidden;

    .brand__card-image {
        width: 100%;
        height: auto;
        @include mixins.transition(transform 0.2s);
    }

    .brand__image-hover {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        opacity: 1;
        @include mixins.transition(background-color 0.2s, opacity 0.2s);
    }

    .brand__card-title {
        position: absolute;
        left: 50%;
        bottom: 5%;
        text-align: center;
        white-space: nowrap;
        letter-spacing: 2px;
        margin: 0;
        padding: $brand-card-title-padding;
        font-size: $brand-card-title-font-size;
        transform: translate(-50%, 0);
        color: #ffffff;
        background-color: transparent;
        @include mixins.transition(all 0.2s);
    }
}

@media (hover: hover) {
    .brand__card:hover {
        cursor: pointer;
    }

    .brand__card:hover .brand__image-hover {
        opacity: 0.6;
        background-color: grey;
    }
        
    .brand__card:hover .brand__card-image {
        transform: scale(1.2);
    }
        
    .brand__card:hover .brand__card-title {
        bottom: 50%;
        transform: translate(-50%, 50%);
    }
} 

@media only screen and(max-width: variables.$md-breakpoint) {
    .brand__card {
        width: $brand-card-image-width-md;
        height: $brand-card-image-height-md;
        margin: 10px;
                
        .brand__card-image {
            transition-property: none !important;
            transform: none !important;
            animation: none !important;
        }

        .brand__card-title {
            font-size: $brand-card-title-font-size-md;
            padding: $brand-card-title-padding;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .brand__card {
        margin: 5px;
        width: $brand-card-image-width-sm;
        height: $brand-card-image-height-sm;
                
        .brand__card-image {
            transition-property: none !important;
            transform: none !important;
            animation: none !important;
        }

        .brand__card-title {
            font-size: $brand-card-title-font-size-sm;
            padding: $brand-card-title-padding;
            letter-spacing: 1px;
        }
    }
}