@use '../../styles/variables' as variables;


.about {
    margin: variables.$section-margin;
    width: 600px;

    .about__header {
        position: relative;
        margin-bottom: 50px;
    }

    .about__header:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: -20px;
        width: 60px;
        height: 4px;
        background-color: #000000;
    }

    .about__paragraph {
        margin: 15px 0;
        font-size: 14px;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .about {

        .about__paragraph {
            font-size: 10px;
        }
    }
}