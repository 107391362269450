@use './variables' as variables;
@use './mixins' as mixins;
@use './functions' as functions;


$header-label-font-size: 20px;


body {
    padding: 0 !important;
    margin: 0 !important;
    color: variables.$body-text-color;
    background-color: variables.$body-bg-color;
    font-family: variables.$primary-font-family;
    // font-family: Helvetica, sans-serif
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    color: #000000;
}

.header-label {
    font-size: $header-label-font-size;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
}

.header-label:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 60px;
}

.label-white:before {
    border-bottom: 5px solid #ffffff;
}

.label-black:before {
    border-bottom: 5px solid #000000;
}


@media only screen and (max-width: variables.$md-breakpoint) {
    .header-label {
        font-size: functions.sizeScaling($header-label-font-size, variables.$md-scaling);
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .header-label {
        font-size: functions.sizeScaling($header-label-font-size, variables.$sm-scaling);
    }
}