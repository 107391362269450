@use '../../styles/variables' as variables;


.post {
    margin: 12px;
    width: 400px;
    height: auto;
    min-height: 240px;

    .post__link {

        .post__image {
            width: 100%;
            height: auto;
            opacity: 1;
            transition: opacity 0.4s;
        }

        .post__title {
            min-height: 45px;
            margin: 5px 0;
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            text-align: center;
            letter-spacing: 2px;
        }
    }
}

.post:hover .post__image {
    opacity: 0.7;
}

@media only screen and (max-width: 1400px) {

    .post {
        margin: 8px;
        width: 300px;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .post {
        margin: 5px;
        width: 120px;
        min-height: 115px;
    
        .post__link {
    
            .post__title {
                font-size: 8px;
                letter-spacing: 0.6px;
            }
        }
    }
}