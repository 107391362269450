@use '../../styles/variables' as variables;


.posts {

    .posts-heading {
        font-size: 24px;
        font-weight: bold;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .posts {

        .posts-heading {
            font-size: 16px;
            font-weight: bold;
        }
    }
}