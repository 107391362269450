@use '../../styles/variables' as variables;
@use '../../styles/functions' as functions;


$carousel-width: 400px;
$carousel-height: 600px;


.carousel {
    width: $carousel-width;
    height: $carousel-height;

    .carousel-inner {

        .carousel-item {

            .carousel-image {
                width: 100%;
                height: 100%;
                z-index: -1;
            }
        }
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {
    .carousel {
        width: functions.sizeScaling($carousel-width, variables.$md-scaling);
        height: functions.sizeScaling($carousel-height, variables.$md-scaling);
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .carousel {
        width: functions.sizeScaling($carousel-width, variables.$sm-scaling);
        height: functions.sizeScaling($carousel-height, variables.$sm-scaling);
    }
}