@use '../../styles/variables';


.product-card {
    width: 250px;
    height: auto;

    .product-header {

        .product-header__image {
            width: 100%;
            height: auto;
            opacity: 1;
            transition: opacity 0.3s;
        }
    }

    .product-body {
        height: 100px;
        padding: 10px;

        .product-body__name {
            font-size: 14px;
            font-weight: 600;
        }

        .product-body__brand {
            font-size: 13px;
        }
    }
}

@media only screen and (max-width: variables.$lg-breakpoint) {}

@media only screen and (max-width: variables.$md-breakpoint) {}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .product-card {
        width: 150px;
        height: auto;
    
        .product-header {
    
            .product-header__image {
                width: 100%;
                height: auto;
                opacity: 1;
                transition: opacity 0.3s;
            }
        }
    
        .product-body {
            height: 80px;
            padding: 8px;
    
            .product-body__name {
                font-size: 11px;
                font-weight: 600;
            }
    
            .product-body__brand {
                font-size: 10px;
            }
        }
    }
}

.product-card:hover .product-header__image {
    opacity: 0.6;
}