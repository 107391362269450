@use '../../styles/variables' as variables;


.overlay-navigation-icon {
    font-size: 64px;
    margin: 0 36px;

    @media (hover: hover) {
        
        &:hover {
            cursor: pointer;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .overlay-navigation-icon {
        font-size: 30px;
    }
}
