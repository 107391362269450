

.dropdown__item {

    .dropdown__link {
        display: block;
        padding: 8px 6px;
        font-size: 12px;
        transition: background-color 0.2s;
    }

    .dropdown__link:hover {
        background-color: #f2f2f2;
    }
}

