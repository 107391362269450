@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixins;


.article-card {
    position: relative;
    margin: 20px 12px;
    width: 350px;
    height: auto;
    animation: fade 0.2s ease-in;

    .article-card__header {
        overflow: hidden;
        
        .article-card__image {
            @include mixins.transition(transform 0.2s);
            // Card image size: 350px / 260px?
            width: 100%;
            height: auto;
        }
    }

    .article-card__body {
        padding: 30px 20px;

        .article-card__title {
            font-size: 19px;
            font-weight: 600;
            margin: 0;
            letter-spacing: 2px;
        }
    
        .article-card__author {
            padding: 8px 0;
            font-size: 12px;
            font-style: italic;
            letter-spacing: 2px;
        }
    
        .article-card__short-content {
            margin: 20px 0;
            font-size: 13px;
        }
    
        .article-card__date {
            font-size: 12px;
            font-style: italic;
            text-align: end;
            letter-spacing: 2px;
        }
    }

    @media (hover: hover) {

        &:hover .article-card__image {
            transform: scale(1.2);
        }
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {

    .article-card {
        width: 200px;

        .article-card-body {

            .article-card-body__title {
                font-size: 24px;
            }
    
            .article-card-body__author {
                font-size: 13px;
            }
    
            .article-card-body__short-content {
                font-size: 14px;
            }
    
            .article-card-body__date {
                font-size: 13px;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .article-card {
        width: 160px;
        margin: 10px 6px;

        .article-card__body {
            padding: 15px 10px;

            .article-card__title {
                font-size: 10px;
                letter-spacing: 1px;
            }
    
            .article-card__author {
                font-size: 9px;
                letter-spacing: 1px;
            }
    
            .article-card__short-content {
                font-size: 9px;
            }
    
            .article-card__date {
                font-size: 9px;
                letter-spacing: 1px;
            }
        }
    }
}