@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixin;
@use '../../styles/functions' as functions;


.homepage-card {
    position: relative;
    animation: fade 0.2s ease-in;
    margin: 6px;
    // height: auto;

    @keyframes fade {
        0% {
            opacity: 0;
        }
    }

    .homepage-card__image {
        max-width: 100%;
        height: auto;
        @include mixin.transition(opacity 0.2s);
    }

    .homepage-card__text {
        position: absolute;
        bottom: 5%;
        left: 10%;
        font-size: 22px;
        text-transform: uppercase;
        color: #ffffff;
        letter-spacing: 4px;
        background-color: transparent;
        @include mixin.transition(background-color 0.2s);
    }

    .homepage-card__copyright {
        position: absolute;
        bottom: 0;
        right: 3px;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.4);
    }
}

@media (hover: hover) {

    .homepage-card:hover .homepage-card__image {
        opacity: 0.6;
        cursor: pointer;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .homepage-card {
        margin: 2px;

        .homepage-card__text {
            font-size: 10px;
            letter-spacing: 1px;
        }

        .homepage-card__copyright {
            font-size: 6px;
        }
    }
}