@use '../../styles/variables';


.gift-guide-logo {
    width: fit-content;
    color: #ffffff;

    .gift-guide-logo__background-image {
        width: 100%;
        height: auto;
    }

    // .gift-guide-logo__inner {
    //     position: absolute;
    //     width: 100%;
    //     top: 25%;
    //     left: 50%;
    //     transform: translateX(-50%);

    //     .inner__icon {
    //         width: 100px; 
    //         height: auto; 
    //         padding-bottom: 25px;
    //     }

    //     .inner__header {
    //         font-family: 'Cinzel', serif;
    //         font-weight: 600;
    //         font-size: 60px;
    //     }
    
    //     .inner__text {
    //         font-size: 26px;
    //         font-family: 'Cinzel', serif;
    //         font-weight: 400;
    //     }
    // }
}

// @media only screen and (max-width: variables.$lg-breakpoint) {

//     .gift-guide-logo {

//         .gift-guide-logo__inner {

//             .inner__icon {
//                 width: 70px; 
//                 padding-bottom: 20px;
//             }

//             .inner__header {
//                 font-size: 36px;
//             }
        
//             .inner__text {
//                 font-size: 18px;
//             }
//         }
//     }
// }

// @media only screen and (max-width: variables.$md-breakpoint) {

//     .gift-guide-logo {

//         .gift-guide-logo__inner {

//             .inner__icon {
//                 width: 60px; 
//                 padding-bottom: 18px;
//             }

//             .inner__header {
//                 font-size: 28px;
//             }
        
//             .inner__text {
//                 font-size: 14px;
//             }
//         }
//     }
// }

// @media only screen and (max-width: variables.$sm-breakpoint) {

//     .gift-guide-logo {

//         .gift-guide-logo__inner {

//             .inner__icon {
//                 width: 50px; 
//                 padding-bottom: 16px;
//             }

//             .inner__header {
//                 font-size: 25px;
//             }
        
//             .inner__text {
//                 font-size: 12px;
//             }
//         }
//     }
// }