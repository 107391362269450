@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixins;


.navigation {
    font-family: variables.$navigation-font-family;
    background-color: variables.$navigation-bg-color;

    .navigation__list {
        margin: 0;
        padding: 0; 

        .navigation__list-item:hover .navigation__link { color: variables.$on-hover-color; }
    
        .navigation__list-item:hover .navigation__dropdown-list {   
            visibility: visible;
            opacity: 1;
        }
    }
}

