

.navigation-item {
    position: relative;
    display: inline-block;
    padding: 15px;
    font-size: 16px;
    letter-spacing: 2px;
    cursor: pointer;

    .navigation-link {
        display: block;
        transition: color 0.2s;
    }

    .navigation-link:hover {
        color: #aaa;
    }
}