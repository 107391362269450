@use '../../styles/variables' as variables;


.guide {
    margin: 50px 0;

    .guide-header {

        .guide-header__text {
            width: 80%;
            margin: 50px 0;

            .guide-header__heading {
                font-family: 'Cinzel', serif;
                text-align: center;
                margin-top: 60px;
            }

            // .text__paragraph {
            //     font-family: Helvetica, sans-serif;
            //     font-size: 17px;
            //     text-align: justify; 
            //     margin: 0;
            // }

            // .text-indent {
            //     text-indent: 50px;
            // }
        }
    }

    .guide-body__products {
        
        .guide-body__background-image {
            background-size: cover;
            background-position: center;
            background-repeat: repeat-y;
        }
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {

    .guide {

        .guide-header {

            .guide-header__text {

                .text__paragraph {
                    font-size: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .guide {

        .guide-header {

            .guide-header__text {

                .text__paragraph {
                    font-size: 14px;
                }

                .guide-header__heading {
                    font-size: 17px;
                    margin-top: 30px;
                }
            }
        }
    }
}