@use '../../styles/variables' as variables;


.latest-article-card {
    width: 350px;
    height: auto;
    margin: 0 5px;

    .latest-article-card__header {
        width: 100%;
        overflow: hidden;

        .latest-article-card__image {
            width: 100%;
            height: auto;
            transition: transform 0.2s;
        }
    }

    .latest-article-card__body {
        padding: 10px 15px;

        .latest-article-card__title {
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 2px;
        }

        .latest-article-card__author {
            font-size: 16px;
        }
    }

    @media (hover: hover) {

        &:hover .latest-article-card__image {
            transform: scale(1.2);
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .latest-article-card {
        width: 110px;
        max-height: 150px;

        .latest-article-card__body {
            padding: 6px 8px;

            .latest-article-card__title {
                font-size: 8px;
                letter-spacing: 1px;
            }

            .latest-article-card__author {
                font-size: 8px;
            }
        }
    }
}