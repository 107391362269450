@use '../../styles/variables' as variables;


.products {
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;

    .products__header {
        margin: 20px 0;

        .products__title {
            font-size: 22px;
            margin: 15px 0;
        }

        .products__separator {
            width: 25px;
            height: 1px;
            background-color: #000000;
        }
    }

    .products__text {
        margin: 0 10px;
        text-align: center;

        * {
            font-family: variables.$primary-font-family !important;
            font-size: 14px !important;
            line-height: 1.8 !important;
        }
    }

    .products__grid {
        display: flex;
        width: auto;

        .products__grid-column {
            background-clip: padding-box;

            .products__product {
                width: 200px;
                height: auto;
                margin: 10px 5px;
            
                .products__image-hover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: transparent;
                    transition: opacity 0.2s, background-color 0.2s;
                }
            
                .products__image-text {
                    position: absolute;
                    bottom: 20%;
                    left: 0;
                    width: 100%;
                    height: auto;
                    text-align: center;
                    color: transparent;
                    transition: color 0.2s;
                }
            
                .products__text-underline {
                    position: absolute;
                    bottom: 18%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 65%;
                    height: 1px;
                    opacity: 0;
                    background-color: #ffffff;
                    transition: opacity 0.2s, background-color 0.2s;
                }
            
                .products__image {
                    width: 100%;
                    height: auto;   
                }
            }

            @media (hover: hover) {

                .products__product:hover .products__image-hover {
                    opacity: 0.7;
                    background-color: grey;
                }

                .products__product:hover .products__image-text {
                    color: #ffffff;
                }

                .products__product:hover .products__text-underline {
                    opacity: 1;
                    background-color: #ffffff;
                }
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .products {

        .products__header {
            margin: 10px 0;

            .products__title {
                font-size: 14px;
                margin: 12px 0;
            }
        }

        .products__text {
            
            * {
                font-size: 10px !important;
                letter-spacing: 1.6 !important;
            }
        }

        .products__grid {

            .products__grid-column {

                .products__product {
                    width: 80px;
                    height: auto;

                    .products__image-text {
                        // font-size: 10px;
                        // color: #ffffff;
                        display: none;
                    }
    
                    .products__text-underline {
                        // opacity: 1;
                        // background-color: #ffffff;
                        display: none;
                    }
    
                }
            }
        }
    }
}