@use '../../styles/variables' as variables;
@use '../../styles/global.scss' as global;
@use '../../styles/functions' as functions;


$header-bg-color: #ffffff;

.header {
    padding: 40px 0;
    height: auto;
    background-color: $header-bg-color;
    
    .header__empty-space {
        width: 127px;
    }
}


