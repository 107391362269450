@use '../../styles/variables' as variables;


.terms {
    width: 800px;
    margin: variables.$section-margin;

    .terms__main-header {
        text-align: center;
        margin-bottom: 30px;
    }

    .terms__sub-header {
        position: relative;
        text-align: center;
        margin-bottom: 50px;
    }

    .terms__sub-header:after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: -20px;
        width: 60px;
        height: 4px;
        background-color: #000000;
    }

    .terms__paragraph {

        .terms__paragraph-header {
            margin: 25px 0;
            text-align: center;
        }

        .terms__paragraph-list {

            .terms__paragraph-nested-list {
                list-style-type: lower-latin;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .terms {

        .terms__paragraph {
            
            .terms__paragraph-list {
                font-size: 14px;
            }
        }
    }
}