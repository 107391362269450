@use '../../styles/mixins' as mixin;


.scroll-top {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 0 10px;
    background-color: transparent;
    @include mixin.transition(bottom 0.2s);

    .scroll-top__arrow {
        font-size: 40px;
        padding: 20px 10px;
    }
}

@media (hover:hover) {
    .scroll-top:hover {
        cursor: pointer;
        bottom: 15px;
    }
}
