@use '../../styles/variables';


.gift-category-item {
    width: 500px;
    height: auto;

    .gift-category-item__image {
        width: 100%;
        height: auto;
        opacity: 1;
        transition: opacity 0.3s;
    }

    .gift-category-item__text {
        width: 100%;
        text-align: center;
        padding: 25px 0;
        font-size: 28px;
        background-color: #ffffff;
    }
}

.gift-category-item:hover .gift-category-item__image {
    opacity: 0.6;
}

@media only screen and (max-width: variables.$md-breakpoint) {

    .gift-category-item {
        width: 440px;

        .gift-category-item__text {
            padding: 22px 0;
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .gift-category-item {
        width: 360px;

        .gift-category-item__text {
            padding: 19px 0;
            font-size: 20px;
        }
    }
}