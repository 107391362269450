@use '../../styles/variables' as variables;


.post-card-extended {
    width: 100%;
    height: auto;
    margin-bottom: 50px;

    .post-card-extended__thumbnail {

        img {
            width: 100%;
            height: auto;
            opacity: 1;
            transition: opacity 0.2s;

            @media (hover: hover) {

                &:hover {
                    opacity: 0.7;
                }
            }
            
        }
    }

    .post-card-extended__info {
        max-width: 380px;
        min-width: 380px;
        height: 660px;

        .post-card-extended__info-title {
            font-size: 26px;
            padding: 0 5px;
            text-align: center;
            font-weight: bold;
            letter-spacing: 3px;
        }

        .post-card-extended__info-products {}

        .post-card-extended__button {
            padding: 15px 30px;
            border: 1px solid #000000;
            transition: box-shadow 0.2s;
            font-size: 16px;
            letter-spacing: 2px;

            @media (hover: hover) {

                &:hover {
                    box-shadow: 0px 5px 25px -15px rgba(0, 0, 0, 1);
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {

    .post-card-extended {
        
        .post-card-extended__info {
            height: 530px;
        }
    }
}

@media only screen and (max-width: 1200px) {

    .post-card-extended {
        
        .post-card-extended__info {
            height: 400px;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    
    .post-card-extended {
        margin-bottom: 25px;
        
        .post-card-extended__info {
            min-width: 160px;
            max-width: 160px;
            height: 135px;

            .post-card-extended__info-title {
                font-size: 12px;
                letter-spacing: 1px;
            }
    
            .post-card-extended__info-products {}
    
            .post-card-extended__button {
                font-size: 9px;
                padding: 3px 11px;
                letter-spacing: 1px;
            }
        }
    }
}