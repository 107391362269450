@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixins;


.fixed-navigation {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 50%;
    padding: 0;
    transform: translate(-50%, 0);
    font-family: variables.$navigation-font-family;
    background-color: variables.$fixed-navigation-bg-color;

    .fixed-navigation__logo {
        margin: 0 5px;
    }

    .fixed-navigation__navigation {

        .fixed-navigation__list {
            margin: 0;
            padding: 0;
    
            .fixed-navigation__list-item {
                position: relative;
                display: inline-block;
                padding: 15px;
                font-size: 16px;
                cursor: pointer;
    
                .fixed-navigation__link {
                    @include mixins.transition(color 0.2s);
                }
            }
    
            .fixed-navigation__list-item:hover .fixed-navigation__link { color: variables.$on-hover-color; }
        }
    }
}

@media screen and (max-width: variables.$lg-breakpoint) {
    
    .fixed-navigation {
        display: none !important;
    }
}
