@use '../../styles/variables' as variables;


.contact-item {
    display: block;
    padding: 10px 20px;
    margin: 6px 0;
    border: 1px solid #000000;
    transition: box-shadow 0.2s;

    .contact-item__icon {
        margin: 0 15px;

        svg {
            font-size: 30px !important;
        }
    }

    .contact-item__text {
        font-size: 15px;
        letter-spacing: 2px;
        word-break: break-word;
    }

    &:hover {
        box-shadow: 0px 10px 15px -15px rgba(66, 68, 90, 1);
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {
    .contact-item {
        padding: 12px 17px;
        margin: 4px 0;
        transition: box-shadow 0.2s;
    
        .contact-item__icon {
            margin: 0 12px;
        }
    
        .contact-item__text {
            font-size: 16px;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .contact-item {
        padding: 10px 15px;
        margin: 4px 0;
    
        .contact-item__icon {
            margin: 0 10px;

            svg {
                font-size: 20px !important;
            }
        }
    
        .contact-item__text {
            font-size: 11px;
            letter-spacing: 1px;
        }
    }
}