

.dropdown {
    list-style-type: none;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    padding: 0;
    background-color: #ffffff;
    min-width: 150px;
    z-index: 1;
    transition: visibility 0s, opacity 0.2s;
}

.dropdown--active {
    visibility: visible;
    opacity: 1;
}

.dropdown--inactive {
    visibility: hidden;
    opacity: 0;
}