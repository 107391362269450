@use '../../styles/variables' as variables;


.products-widget {

    .products-widget__arrow {
        font-size: 21px;
    }

    .products-widget__product {
        width: 100px;
        height: 120px;
        margin: 0 3px;

        .products-widget__product-image {
            width: 100%;
            height: auto;
        }

        .products-widget__title {
            position: absolute;
            opacity: 0;
            z-index: 2;
            bottom: 10px;
            width: 100%;
            color: #ffffff;
            font-size: 16px;
            text-align: center;
            transition: opacity 0.2s;
        }

        .products-widget__product-hover {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: transparent;
            transition: opacity 0.2s, background-color 0.2s;
        }

        @media (hover: hover) {

            &:hover .products-widget__product-hover {
                opacity: 0.7;
                background-color: grey;
                cursor: pointer;
            }

            &:hover .products-widget__title {
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .products-widget {
        max-width: 393px !important;

        .products-widget__arrow {
            font-size: 14px;
        }

        .products-widget__product {
            width: 42px;
            height: auto;
            margin: 0 1px;

            .products-widget__title {
                display: none;
            }

            .products-widget__product-hover {
                display: none;
            }
        }
    }
}