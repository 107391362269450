@use '../../styles/variables' as variables;


.article-navigation {
    
    .article-navigation__link {

        .article-navigation__title {
            font-size: 20px;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .article-navigation {
        width: 160px;
        
        .article-navigation__link {
    
            .article-navigation__title {
                font-size: 12px;
            }
        }
    }
}