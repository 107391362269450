$body-bg-color: #ffffff;
$body-text-color: #000000;
$on-hover-color: #aaa;
$section-margin: 100px 0;


$navigation-bg-color: #ffffff;
$fixed-navigation-bg-color: #ffffff;
$footer-bg-color: #ffffff;


// FONTS 
// $navigation-font-family: 'Andada Pro', serif;
// $primary-font-family: 'Andada Pro', serif;

// $navigation-font-family: 'EB Garamond', serif;
// $primary-font-family: 'EB Garamond', serif;

// $navigation-font-family: 'Poppins', sans-serif;
// $primary-font-family: 'Poppins', sans-serif;

// $navigation-font-family: Georgia, 'Times New Roman', Times, serif;
// $primary-font-family: Georgia, 'Times New Roman', Times, serif;

$navigation-font-family: 'Helvetica', sans-serif;
$primary-font-family: 'Helvetica', sans-serif;


// RWD 
$sm-breakpoint: 576px;
$md-breakpoint: 768px;
$lg-breakpoint: 992px;

$sm-scaling: 0.3;
$md-scaling: 0.2;