@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixin;


.footer {
    margin-top: 25px;
    background-color: variables.$footer-bg-color;

    .footer__navigation {
        font-size: 16px;
        text-transform: uppercase;
        margin: 10px 0;
        letter-spacing: 2px;

        .footer__navigation-link {
            margin: 0 6px;
            transition: color 0.2s;
        }

        @media (hover: hover) {

            .footer__navigation-link:hover {
                color: variables.$on-hover-color;
            }
        } 
    }

    .footer__contact {

        .footer__contact-icon {
            font-size: 16px;
            margin-right: 4px;
        }

        .footer__contact-email {
            font-size: 16px;
            letter-spacing: 2px;
        }
    }

    .footer__social-media {
        margin: 40px 0;
    }

    .footer__logo {}

    .footer__copyright {
        letter-spacing: 2px;
    }

    .footer__author {
        margin-top: 50px;
        margin-left: 3px;

        .footer__author-name {
            font-size: 14px;
            margin: 0;
            letter-spacing: 2px;
        }

        .footer__author-link {
            margin: 0 8px;

            .footer__author-icon {
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .footer {

        .footer__navigation {
            font-size: 14px;
            margin: 5px 0;
            letter-spacing: 1px;
        }

        .footer__contact {

            .footer__contact-icon {
                font-size: 14px;
            }
    
            .footer__contact-email {
                font-size: 14px;
                letter-spacing: 1px;
            }
        }

        .footer__social-media {
            margin: 16px 0;
        }

        .footer__copyright {
            font-size: 12px;
            letter-spacing: 1px;
        }

        .footer__author {
            margin-top: 35px;
    
            .footer__author-name {
                font-size: 12px;
                letter-spacing: 1px;
            }
    
            .footer__author-link {
                margin: 0 6px;

                .footer__author-icon {
                    font-size: 14px;
                }
            }
        }
    }
}