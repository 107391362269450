@use '../../styles/variables';


.category-header {

    .category-header__image {
        width: 100%;
        height: auto;
    }

    .category-header__title {
        margin: 0;
        padding: 10px 40px;
        color: #000000;
        background-color: #ffffff;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .category-header {

        .category-header__image {
            width: 100%;
            height: auto;
        }
    
        .category-header__title {
            font-size: 20px;
            padding: 5px 25px;
        }
    }
}