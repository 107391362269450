@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixin;
@use '../../styles/functions' as functions;


.homepage {

    .latest-articles {
        margin-top: 100px;

        .latest-articles-button {
            padding: 12px 25px;
            font-size: 16px;
            border: 1px solid #000000;
            transition: box-shadow 0.2s;
            letter-spacing: 2px;

            @media (hover: hover) {

                &:hover {
                    box-shadow: 0px 5px 25px -15px rgba(0, 0, 0, 1);
                }
            }
        }
    }

    .homepage__cards-container {
        margin: 80px 0;
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {
    .homepage {

        .latest-articles {
    
            .latest-articles__button {
                padding: 13px 23px;
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .homepage {

        .latest-articles {
            margin-top: 50px;

            .wrapper {
                align-items: center;
            }

            .latest-articles-button {
                padding: 6px 16px;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }
    }
}