@use '../../styles/variables' as variables;


.article-overview {

    .article-header {
        padding: 0 10px;

        hr {
            margin: 10px 0;
        }

        .article-header__title {
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .article-details {
            color: #a6a6a6;

            & > * {
                font-size: 14px;
                letter-spacing: 2px;
            }
        }
    }
    

    .article-body {
        max-width: 740px;
        margin-right: auto; 
        margin-left: auto;
        padding-right: 10px;
        padding-left: 10px;

        * {
            font-size: 14px;
            font-family: variables.$primary-font-family !important;
            letter-spacing: 0.5px;
            word-spacing: 1.5px;
        }

        p {
            margin-bottom: 0;
        }

        img {
            height: auto;
        }
    }

    .article-footer {

        .article-footer__wrapper {

            .article-footer__left-arrow,
            .article-footer__right-arrow {
                margin: 0 8px;
                font-size: 30px;
            }
        }
    }
}


@media only screen and (max-width: variables.$md-breakpoint) {

    .article-overview {

        .article-header {

            hr {
                margin: 5px 0;
            }

            .article-header__title {
                font-size: 26px;
            }

            .article-details {

                & > * {
                    font-size: 12px;
                }
            }
        }

        .article-body {
            width: 540px;
            font-size: 15px;

            img {
                width: 85%;
            }
        }
    }
} 

@media only screen and (max-width: variables.$sm-breakpoint) {

    .article-overview {

        .article-header {

            .article-header__title {
                font-size: 14px;
            }

            .article-details {

                .article-details__author,
                .article-details__date {
                    font-size: 10px;
                }
            }
        }

        .article-body {
            width: 380px;
            font-size: 11px;

            * {
                font-size: 10px;
                word-spacing: 1px;
            }

            img {
                width: 85%;
            }
        }

        .article-footer {

            .article-footer__wrapper {
                flex-direction: column !important;

                .article-footer__left-arrow,
                .article-footer__right-arrow {
                    margin: 0 5px;
                    font-size: 25px;
                }
            }
        }
    }
} 