@use '../../styles/variables' as variables;


.magazine {
    
    .magazine__header {
        text-align: center;
        font-size: 24px;
        margin: 25px 0;
        font-weight: bold;
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .magazine {

        .magazine__header {
            font-size: 16px;
        }
    }
}