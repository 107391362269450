@use '../../styles/variables';


.for-kid {
    margin: 50px 0;

    .for-kid__intro {
        font-size: 22px;
        text-align: justify; 
        text-indent: 50px;
        font-weight: bold;
        padding: 25px;
        margin-top: 40px;
    }

    .for-kid__products {
        margin: variables.$section-margin;
    }
}

@media only screen and (max-width: variables.$md-breakpoint) {

    .for-kid {
    
        .for-kid__intro {
            font-size: 16px;
            text-align: 'justify'; 
            text-indent: 50px;
            font-weight: bold;
            padding: 22px;
            margin-top: 36px;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .for-kid {
    
        .for-kid__intro {
            font-size: 14px;
            text-align: 'justify'; 
            text-indent: 50px;
            font-weight: bold;
            padding: 19px;
            margin-top: 32px;
        }
    }
}