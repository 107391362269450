@use '../../styles/variables';


.slider {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    
    .slider__inner {
        position: relative;
        width: 100%;
        height: 100%;

        .slider__slide {
            position: relative;
            z-index: 4;
            width: 100%;
            height: 100%;

            .slider__image {
                display: block;
                width: 100%;
                height: auto;
                animation: fade 1s ease-in-out;
            }
    
            @keyframes fade {
                0% {
                    opacity: 0;
                }
            }
    
            .slider__content {
                position: absolute;
                z-index: 3;
                width: 100%;
                top: 50%;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, -50%);
                transition: opacity 0.4s;
        
                .slider__title {
                    font-size: 18px;
                }
        
                .slider__separator {
                    width: 40%;
                    height: 1px;
                    background-color: #000000;
                }
        
                .slider__text {
                    font-size: 12px;
                }
            }
    
            .slider__content > * {
                margin: 5px 0;
            }
        }

        .slider__slide::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            opacity: 0;
            z-index: 2;
            transition: opacity 0.4s;
        }

        .slider__slide:hover::before {
            opacity: 0.5;
        }

        .slider__slide:hover .slider__content {
            opacity: 1;
        }
    }

    .indicators {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        z-index: 4;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        transition: visibility 0.4s, opacity 0.4s;

        .indicator {
            width: 80px;
            height: 8px;
            margin: 0 8px;
            background-color: #ffffff;
            cursor: pointer;
        }

        .active {
            background-color: #000000;
        }
    }

    .indicators:hover .slider__inner .slider__slide .slider__content {
        opacity: 0;
    }

    // .prev,
    // .next {
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;
    //     width: 5%;
    //     margin: 0 10px;
    //     cursor: pointer;
    // }
    
    // .prev {
    //     left: 0;
    // }
    
    // .next {
    //     right: 0;
    // }
}

.slider:hover .indicators {
    visibility: visible;
    opacity: 1;
}

@media only screen and (max-width: variables.$md-breakpoint) {
    
    .slider {

        .indicators {

            .indicator {
                width: 60px;
                height: 5px;
                margin: 0 6px;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .slider {

        .slider__inner {

            .slider__slide {

                .slider__content {
                    opacity: 1;

                    .slider__title {
                        font-size: 10px;
                    }

                    .slider__text {
                        font-size: 7px;
                    }
                }

                .slider__content > * {
                    margin: 2px 0;
                }
            }

            .slider__slide::before {
                opacity: 0.3;
            }
        }

        .indicators {
            visibility: visible;
            opacity: 1;

            .indicator {
                width: 45px;
                height: 6px;
                margin: 0 4px;
            }
        }
    }
}