@use '../../styles/variables' as variables;


.category-card {
    position: relative;
    margin: 0 6px;
    width: 250px;
    height: auto;

    .category-card__link {
        display: block;
    }

    .category-card__hover {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        opacity: 1;
        transition: background-color 0.4s, opacity 0.4s;
    }

    .category-card__image {
        width: 100%;
        height: auto;
    }

    // .category-card__title {
    //     position: absolute;
    //     left: 50%;
    //     bottom: 50%;
    //     transform: translate(-50%, 50%);
    //     font-size: 20px;
    //     text-align: center;
    //     font-weight: bold;
    //     color: #ffffff;
    // }
}

@media (hover: hover) {

    .category-card:hover .category-card__hover {
        opacity: 0.6;
        background-color: grey;
        cursor: pointer;
    }
}

@media only screen and(max-width: variables.$sm-breakpoint) {

    .category-card {

        .category-card__hover {
            opacity: 0.2;
            background-color: grey;
        }

        .category-card__title {
            font-size: 10px;
        }
    }
}