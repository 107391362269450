@use '../../styles/variables' as variables;
@use '../../styles/functions' as functions;


$loader-width: 65px;
$loader-height: 65px;
$border: 10px;
$loader-scaling: 0.3;



.loader {
    border: $border solid #f3f3f3;
    border-top: $border solid #000000;
    border-radius: 50%;
    width: $loader-width;
    height: $loader-height;
    margin-top: 150px;
    margin-bottom: 300px;
    -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .loader {
        border: functions.sizeScaling($border, $loader-scaling);
        border-top: functions.sizeScaling($border, $loader-scaling);
        width: functions.sizeScaling($loader-width, $loader-scaling);
        height: functions.sizeScaling($loader-height, $loader-scaling);
    }
}