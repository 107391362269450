@use '../../styles/variables' as variables;


.post-overview {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    .post-header {
        padding: 0 35px;

        .post-header__title {
            font-size: 18px;
            text-align: center;
            font-weight: bold;
            letter-spacing: 2px;
        }

        .post-header__date {
            font-size: 13px;
        }

        .post-header__separator {
            width: 25px;
            height: 1px;
            background-color: #000000;
        }

        .post-header__image {
            margin: 25px 0;
            width: 100%;
            height: auto;
        }
    }

    .post-body {
        padding: 0 35px;

        .post-body__title {
            font-family: 'Playfair Display', serif;
            font-size: 75px;
            margin: 25px 0;
        }

        .post-body__text {
            text-align: center;

            * {
                font-family: variables.$primary-font-family !important;
                font-size: 14px !important;
                line-height: 1.8 !important;
            }
        }

        .post-body__products {
            margin: 45px 0;
        }

        .post-body__images {}

        .post-body__image {
            margin: 10px 0;
            width: 100%;
            height: auto;
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {

    .post-overview {
    
        .post-header {
    
            .post-header__title {
                font-size: 12px;
                letter-spacing: 1px;
            }

            .post-header__date {
                font-size: 10px;
            }
        }
    
        .post-body {

            .post-body__title {
                font-family: 'Playfair Display', serif;
                font-size: 35px;
                margin: 25px 0;
            }

            .post-body__title {
                margin: 20px 0;
            }
            
            .post-body__text {

                * {
                    font-size: 10px !important;
                    line-height: 1.6 !important;
                }
            }

            .post-body__products {
                margin: 15px 0;
            }

            .post-body__image {

            }
        }
    }
}