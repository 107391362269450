@use '../../styles/variables' as variables;
@use '../../styles/mixins' as mixins;
@use '../../styles/functions' as functions;


$overlay-navigation-link-size: 30px;
$collapsible-list-link-size: 20px;
$arrow-size: 32px;
$sm-scaling: 0.2;


.overlay-navigation {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-y: hidden;
    transition: height 0.2s;

    .overlay-navigation__close-button {
        position: absolute;
        top: 25px;
        right: 25px;

        @media (hover: hover) {
            &:hover {
                cursor: pointer;
            }
        }
    }

    .overlay-navigation__content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;   

        .overlay-navigation__list {
            list-style-type: none;
            padding: 0;

            .overlay-navigation__list-item {
                padding: 10px 0;

                .overlay-navigation__link {
                    font-size: 30px;
                    font-weight: 500;
                    letter-spacing: 4px;
                }
            }
        }
        
        .overlay-navigation__logo {
            position: relative;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 50px 0;
        }

        .overlay-navigation__social-media {
            position: relative;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .overlay-navigation {

        .overlay-navigation__content {

            .overlay-navigation__list {

                .overlay-navigation__list-item {

                    .overlay-navigation__link {
                        font-size: 20px;
                    }
                }

                .collapsible-list {

                    .collapsible-list__item {

                        .collapsible-list__link {
                            font-size: functions.sizeScaling($collapsible-list-link-size, $sm-scaling);
                        }
                    }
                }
            }
        }
    }
}

