

.mobile-fixed-navigation {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    padding: 0;
    background-color: #ffffff;

    .mobile-fixed-navigation__wrapper {
        margin: 10px 0;
    }
}