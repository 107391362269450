@use '../../styles/variables' as variables;


$brand-card-placeholder-width: 300px;
$brand-card-placeholder-height: 450px;

$brand-card-placeholder-width-md: 200px;
$brand-card-placeholder-height-md: 300px;

$brand-card-placeholder-width-sm: 160px;
$brand-card-placeholder-height-sm: 240px; 


.brand-card__placeholder {
    margin: 15px;
    width: $brand-card-placeholder-width;
    height: $brand-card-placeholder-height;
    animation: Glow linear 2s infinite;
}

@keyframes Glow {
    0% {background-color: #ffffff;}
    50% {background-color: #f7f7f7;}
    100% {background-color: #ffffff;}
}

@media only screen and(max-width: variables.$md-breakpoint) {
    .brand-card__placeholder {
        width: $brand-card-placeholder-width-md;
        height: $brand-card-placeholder-height-md;
    }
}

@media only screen and(max-width: variables.$sm-breakpoint) {
    .brand-card__placeholder {
        width: $brand-card-placeholder-width-sm;
        height: $brand-card-placeholder-height-sm;
    }
}