@use '../../styles//variables' as variables;
@use '../../styles/mixins' as mixin;
@use '../../styles/functions' as functions;


$social-media-icon-size: 26px;
$social-media-icon-scaling: 0.2;


.social-media {
    margin: 0 8px;
    padding: 10px;
    width: 100px;

    .social-media__item {
        padding: 0 10px;
        
        .social-media__icon {
            font-size: $social-media-icon-size;
            @include mixin.transition(color 0.2s);
        }

        @media (hover: hover) {
            .social-media__icon:hover {
                cursor: pointer;
                color: #aaa;
            }
        }
    }
}

@media only screen and (max-width: variables.$sm-breakpoint) {
    .social-media {

        .social-media__item {

            .social-media__icon {
                font-size: functions.sizeScaling($social-media-icon-size, $social-media-icon-scaling);
            }
        }
    }
    
}